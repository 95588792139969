import Arb from "./arb";
import Aurora from "./aurora";
import Avax from "./avax";
import Boba from "./boba";
import Bsc from "./bsc";
import Btt from "./btt";
import Celo from "./celo";
import Cro from "./cro";
import Eth from "./eth";
import Ftm from "./ftm";
import Heco from "./heco";
import Matic from "./matic";
import Mobm from "./mobm";
import Movr from "./movr";
import Okt from "./okt";
import Op from "./op";
import Sbch from "./sbch";
import Xdai from "./xdai";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Arb,
  Aurora,
  Avax,
  Boba,
  Bsc,
  Btt,
  Celo,
  Cro,
  Eth,
  Ftm,
  Heco,
  Matic,
  Mobm,
  Movr,
  Okt,
  Op,
  Sbch,
  Xdai,
};
