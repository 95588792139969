import { combineReducers } from "redux";

import layout from "./layout/reducer";
import portfolio from "./portfolio/reducer";
import trade from "./trade/reducer";
import account from "./account/reducer";
import currency from "./currency/reducer";
import chains from "./chains/reducer";
import tokens from "./tokens/reducer";
import userTokens from "./userTokens/reducer";
import updateManager from "./updateManager/reducer";

export default combineReducers({
  layout,
  portfolio,
  trade,
  account,
  currency,
  chains,
  tokens,
  userTokens,
  updateManager,
});
