import { v4 } from "uuid";
import { callOnce } from "../helpers/api";

const debankBaseURL = "https://openapi.debank.com/v1";

const getUserMarkets = callOnce((address) =>
  fetch(
    `https://api.debank.com/portfolio/project_list?user_addr=${address}`
  ).then((v) => v.json())
);

const debank = {
  getChains: callOnce(() =>
    fetch(debankBaseURL + "/chain/list").then((v) => v.json())
  ),
  getTokenDetail: (chain_id, token_address) =>
    fetch(
      debankBaseURL + `/token?chain_id=${chain_id}&id=${token_address}`
    ).then((v) => v.json()),

  getUserMarkets,
  getUserTokens: (chain_id, address) =>
    fetch(
      debankBaseURL + `/user/token_list?id=${address}&chain_id=${chain_id}`
    ).then((v) => v.json()),

  getUserDefiTokens: callOnce((address) =>
    getUserMarkets(address)
      .then((v) =>
        Promise.all(
          v.data.map(({ id }) =>
            fetch(
              `https://api.debank.com/portfolio/list?user_addr=${address}&project_id=${id}`
            )
              .then((v) => v.json())
              .then((v) => ({
                portfolio_list: v.data.portfolio_list,
                ...v.data.project,
              }))
              .then((result) => {
                return result.portfolio_list.reduce((acc, item) => {
                  const linkTo = v4();

                  switch (item.detail_types[0]) {
                    case "lending":
                      return [
                        ...acc,
                        ...(item.detail.supply_token_list || []).map((v) => ({
                          ...v,
                          chain_id: v.chain,
                          type: "lending",
                          linkTo,
                          market: item.base.project_id,
                        })),
                      ];
                    case "reward":
                      return [
                        ...acc,
                        ...(item.detail.token_list || []).map((v) => ({
                          ...v,
                          chain_id: v.chain,
                          type: "reward",
                          linkTo,
                          market: item.base.project_id,
                        })),
                      ];
                    case "common":
                      return [
                        ...acc,
                        ...(item.detail.supply_token_list || []).map((v) => ({
                          ...v,
                          chain_id: v.chain,
                          type: "farming",
                          linkTo,
                          market: item.base.project_id,
                        })),
                        ...(item.detail.reward_token_list || []).map((v) => ({
                          ...v,
                          chain_id: v.chain,
                          linkTo,
                          type: "reward",
                          market: item.base.project_id,
                        })),
                      ];
                    default:
                      return acc;
                  }
                }, []);
              })
              .catch((e) => console.log("getUserDefiTokens error ", e))
          )
        )
      )
      .then((v) => v.reduce((acc, it) => [...acc, ...it]))
  ),
};

export default debank;
