// import { connect } from "../../../store";
// import { layout as layoutActions } from "../../../store/actions";
import ConnectWallet from "./ConnectWallet";

import {
  Container,
  Brand,
  Navigation,
  NavItem,
  NavItemIcon,
  NavItemLabel,
  NavTop,
  NavBottom,
  // NavItemAction,
} from "./Styled";

function LeftBar() {
  return (
    <Container>
      <Brand>
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/favicon.png"} alt="" />
        </div>
        <span>Flake Wallet</span>
      </Brand>
      <Navigation>
        <NavTop>
          <NavItem exact to="/">
            <NavItemIcon>
              <i className="material-icons-outlined">savings_outlined</i>
            </NavItemIcon>
            <NavItemLabel>Portfolio</NavItemLabel>
            <div className="tooltip">Portfolio</div>
          </NavItem>
          <NavItem strict to="/trade">
            <NavItemIcon>
              <i className="material-icons-outlined">trending_up</i>
            </NavItemIcon>
            <NavItemLabel>Trade</NavItemLabel>
            <div className="tooltip">Trade</div>
          </NavItem>
          <NavItem strict to="/bots">
            <NavItemIcon>
              <i className="material-icons-outlined">smart_toy</i>
            </NavItemIcon>
            <NavItemLabel>Bots</NavItemLabel>
            <div className="tooltip">Bots</div>
          </NavItem>
          <NavItem strict to="/pool">
            <NavItemIcon>
              <i className="material-icons-outlined">toll</i>
            </NavItemIcon>
            <NavItemLabel>Pool</NavItemLabel>
            <div className="tooltip">Pool</div>
          </NavItem>
          <NavItem strict to="/farm">
            <NavItemIcon>
              <i className="material-icons-outlined">agriculture</i>
            </NavItemIcon>
            <NavItemLabel>Farm</NavItemLabel>
            <div className="tooltip">Farm</div>
          </NavItem>
          <NavItem strict to="/stake">
            <NavItemIcon>
              <i className="material-icons-outlined">business_center</i>
            </NavItemIcon>
            <NavItemLabel>Stake</NavItemLabel>
            <div className="tooltip">Stake</div>
          </NavItem>
        </NavTop>
        <NavBottom>
          {/* <NavItemAction onClick={() => toggleThemeMode()}>
            <NavItemIcon>
              <i className="material-icons-outlined">
                {layout.themeMode === "light" ? "dark_mode" : "light_mode"}
              </i>
            </NavItemIcon>
            <div className="tooltip">
              {layout.themeMode === "light" ? "Dark mode" : "Light mode"}
            </div>
          </NavItemAction> */}
          <ConnectWallet />
          <NavItem strict to="/setting">
            <NavItemIcon>
              <i className="material-icons-outlined">settings</i>
            </NavItemIcon>
            <div className="tooltip">Setting</div>
          </NavItem>
        </NavBottom>
      </Navigation>
    </Container>
  );
}

export default LeftBar;
