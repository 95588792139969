import { UPDATE_DATE, CAN_UPDATE_DATA } from "./actionsTypes";

const defaultState = {
  date: 0,
  canUpdateIn: 60000,
  canUpdate: false,
};

export default function portfolioReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_DATE:
      return {
        ...state,
        date: new Date().getTime(),
      };
    case CAN_UPDATE_DATA:
      return {
        ...state,
        canUpdate: true,
      };
    default:
      return state;
  }
}
