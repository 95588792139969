import {
  GET_TRADE_LOADING,
  GET_TRADE_SUCCESS,
  GET_TRADE_ERROR,
} from "./actionsTypes";

import {
  chains as Chains,
  tokens as Tokens,
  updateManager as UpdateManager,
} from "../actions";

import Services from "../../services";

export function getTrades(userOptions) {
  return async function trigger(dispath) {
    dispath({ type: GET_TRADE_LOADING });
    try {
      const sevices = new Services(userOptions);
      const [chains, tokens] = await Promise.all([
        sevices.getChains(),
        sevices.getTokens(),
      ]);

      const mergedTokenList = [...tokens]
        .filter(
          (v, i, arr) => arr.findIndex((t) => t.address === v.address) === i
        )
        .map(sevices.formatTokenObject);

      Chains.updateList(chains)(dispath);
      Tokens.updateList(mergedTokenList)(dispath);
      UpdateManager.updateDate()(dispath);

      dispath({ type: GET_TRADE_SUCCESS });
    } catch (error) {
      console.error(error);
      dispath({ type: GET_TRADE_ERROR });
    }
  };
}
