import {
  GET_TRADE_LOADING,
  GET_TRADE_SUCCESS,
  GET_TRADE_ERROR,
} from "./actionsTypes";

const defaultState = {
  loading: true,
  error: false,
};

export default function portfolioReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_TRADE_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_TRADE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_TRADE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
