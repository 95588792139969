import styled from "styled-components";

export default styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 25px;
  }
`;
