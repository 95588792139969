import styled from "styled-components";
import { Area, AreaChart, Tooltip, ResponsiveContainer } from "recharts";

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
`;

export default function Line({ configs = [], data = [], tooltipConfigs = [] }) {
  // const [size, setSize] = useState({ width: 0, height: 0 });
  // const [id, setId] = useState("");

  // useEffect(() => {
  //   const realId = v4();

  //   const handleResize = () => {
  //     const element = document.getElementById(realId);
  //     if (element) {
  //       setSize({
  //         width: element.clientWidth,
  //         height: element.clientHeight,
  //       });
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   setId(realId);
  //   setTimeout(() => {
  //     handleResize();
  //   }, 10);

  //   return () => window.removeEventListener("resize", handleResize);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, ["mount"]);

  return (
    <Container>
      <ResponsiveContainer>
        <AreaChart data={data}>
          {configs.map((config, i) => (
            <Area
              key={"area" + config.key}
              type="monotone"
              stackId={config.stackId || 1}
              dataKey={config.key}
              stroke={config.color || "#0089ec"}
              // fillOpacity={1}
              fill={config.color || "#0089ec"}
            />
          ))}
          {!!tooltipConfigs.length && (
            <Tooltip
              content={({ label }) => (
                <div
                  style={{
                    background: "white",
                    boxSizing: "border-box",
                    padding: "5px",
                    borderRadius: "3px",
                    border: "1px solid lightgrey",
                  }}
                >
                  {tooltipConfigs.map((config) => (
                    <div key={config.key}>
                      {config.render
                        ? config.render(data[label][config.key])
                        : data[label][config.key]}
                    </div>
                  ))}
                </div>
              )}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
}
