import styled from "styled-components";
import { connect } from "../../store";
import { withLoading } from "./Loaders";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 110px;
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  .second {
    margin-top: 3px;
    font-size: 13px;
    color: grey;
  }

  .img-container {
    position: relative;
    margin-right: 15px;
    // margin-right: 45px;

    img:nth-child(2) {
      left: 25px;
      position: absolute;
    }
  }
`;
const Div = styled.div``;
const ContentLoader = withLoading(Div);

const Img = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 45px;
  background: white;
  border: 1px solid #f3f3f3;
`;

function CoinName({ id = [], tokens, loading = true }) {
  const items = id.map((i) => tokens[i]);

  return (
    <Container>
      <ContentLoader
        loading={loading}
        className="img-container"
        style={{
          borderRadius: "45px",
          height: "35px",
          width: "35px",
        }}
      >
        {items.map(
          (item) =>
            item && <Img key={item.logo_url} src={item.logo_url} alt="" />
        )}
      </ContentLoader>
      <div
        style={{
          width: "calc(100% - 95px)",
          overflow: "hidden",
        }}
      >
        <ContentLoader className="text" loading={loading}>
          {items.map((v) => (v || {}).name || "").join(" - ") || "Loading ..."}
        </ContentLoader>
        <ContentLoader className="text second" loading={loading}>
          {items.map((v) => (v || {}).symbol || "").join(" - ") ||
            "Loading ..."}
        </ContentLoader>
      </div>
    </Container>
  );
}

export default connect((state) => ({ tokens: state.tokens.data }))(CoinName);
