export default class CommonChain {
  constructor(options) {
    this.options = options;
  }
  getChain = async () => [];
  getTokens = async () => [];
  getUserTokens = async () => [];
  getUserTransactions = async () => [];
  getUserDefiTokens = async () => [];
  swapToken = async () => {};
}
