import {
  TOGGLE_LOADING,
  TOGGLE_THEME_MODE,
  TOGGLE_LEFTBAR,
  EXPAND_LEFTBAR,
} from "./actionsTypes";

const defaultState = {
  themeMode: "light",
  toggleLeftbar: false,
  exandLeftbar: true,
  loading: true,
};

export default function layoutReducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case TOGGLE_THEME_MODE:
      return {
        ...state,
        themeMode: state.themeMode === "light" ? "dark" : "light",
      };
    case TOGGLE_LEFTBAR:
      return {
        ...state,
        toggleLeftbar: !state.toggleLeftbar,
      };
    case EXPAND_LEFTBAR:
      return {
        ...state,
        exandLeftbar: !state.exandLeftbar,
      };
    default:
      return state;
  }
}
