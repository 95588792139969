import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { v4 } from "uuid";
import { connect } from "../../../../store";
import { trade, updateManager } from "../../../../store/actions";
import Balance from "../../../Common/Balance";
import Card from "../../../Common/Card";
import ChainName from "../../../Common/ChainName";
import CoinName from "../../../Common/CoinName";
import Icon from "../../../Common/Icon";

import Row from "../../../Common/Row";
import Table from "../../../Common/Table";

const configs = (push) => [
  {
    id: v4(),
    key: "chain",
    label: "Chain name",
    Render: ({ value, loading }) => <ChainName id={value} loading={loading} />,
    style: { width: "180px" },
    className: "onlyDesktop",
  },
  {
    id: v4(),
    key: "address",
    label: "Name",
    Render: ({ value, loading }) => (
      <CoinName id={value ? [value] : []} loading={loading} />
    ),
  },
  {
    id: v4(),
    key: "price",
    label: "Price",
    Render: ({ value, loading }) => (
      <Balance amount={[{ value, price: 1 }]} loading={loading} oneline />
    ),
    style: { width: "140px" },
  },
  {
    id: v4(),
    key: "chain",
    label: "",
    Render: ({ value, loading, object }) =>
      value === "avax" && (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            background: "#0089ec",
            color: "white",
            cursor: "pointer",
            height: 32,
            borderRadius: 5,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            push(`?action=swap&token0=${object().address}`);
          }}
        >
          <Icon name="swap_horiz" />
        </div>
      ),
    style: { width: "80px", textAlign: "right" },
  },
];

function TradeList({
  getTrades,
  tokens,
  userAddress,
  trade,
  history,
  canUpdateData,
  canUpdate,
}) {
  useEffect(() => {
    canUpdateData();
    if (userAddress && canUpdate) getTrades({ address: userAddress });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress]);
  console.log(trade, tokens);
  return (
    <div key={userAddress}>
      <Row>
        <Card header="Token list" style={{ overflow: "visible" }}>
          <Table
            configs={configs(history.push)}
            data={tokens}
            loading={trade.loading}
          />
        </Card>
      </Row>
    </div>
  );
}

export default withRouter(
  connect(
    ({ account, tokens, trade, updateManager }) => ({
      tokens: Object.values(tokens.data),
      userAddress: account.data.address,
      trade,
      canUpdate: updateManager.canUpdate,
    }),
    {
      getTrades: trade.getTrades,
      canUpdateData: updateManager.canUpdateData,
    }
  )(TradeList)
);
