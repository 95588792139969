import queryString from "query-string";
// import TradingViewWidget from "react-tradingview-widget";
import Card from "../../Common/Card";
// import CoinName from "../../Common/CoinName";

export default function Swap({ location, history }) {
  const search = queryString.parse(location.search);
  const display = search.action === "swap";

  return (
    display && (
      <div
        style={{
          position: "fixed",
          zIndex: 30,
          width: "100%",
          height: "calc(100% - 55px)",
          top: 55,
          left: 0,
          padding: "15px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 0,
            background: "#00000070",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
          onClick={() => history.push("")}
        ></div>
        <div
          style={{
            position: "relative",
            zIndex: 0,
          }}
        >
          <Card
            header="Swap"
            Aside={() => (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => history.push("")}
              >
                X
              </div>
            )}
          >
            <div
              style={{
                boxSizing: "border-box",
                padding: "40px 50px",
              }}
            >
              🚀 Comming soon !
            </div>
            {/* <div
              style={{
                display: "flex",
                flex: 1,
                padding: "15px",
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "60%" }}>
                {search.token0 && (
                  <div
                    style={{
                      height: "50%",
                      width: "100%",
                      paddingBottom: 7,
                      boxSizing: "border-box",
                    }}
                  ></div>
                )}
                {search.token1 && (
                  <div
                    style={{
                      height: "50%",
                      width: "100%",
                      paddingTop: 7,
                      boxSizing: "border-box",
                    }}
                  ></div>
                )}
              </div>
              <div style={{ flex: 1 }}></div>
            </div> */}
          </Card>
        </div>
      </div>
    )
  );
}
