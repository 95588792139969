import { useState } from "react";
import styled from "styled-components";
// import Loading from "./Loading";

const Container = styled.div`
  background: white;
  border-radius: 8px;
  flex: 1;
  /* max-height: 66vh; */
  margin: 10px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 55px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  border-bottom: 1px solid lightgrey;
  font-weight: bold;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  box-sizing: border-box;
  // overflow: hidden;
`;

export default function Card({
  header = "",
  children,
  Aside = () => null,
  ...props
}) {
  const [expand, setExpand] = useState(true);
  return (
    <Container {...props}>
      {header && (
        <Header>
          <span className="title" onClick={() => setExpand(!expand)}>
            {header}
          </span>{" "}
          <Aside />
        </Header>
      )}
      {expand && <Content>{children}</Content>}
    </Container>
  );
}
