import {
  UPDATE_USER_TOKEN_LIST,
  UPDATE_USER_TOKEN_HISTORY_LIST,
} from "./actionsTypes";

const defaultState = {
  data: [],
  history: [],
};

export default function userTokensReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_USER_TOKEN_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_USER_TOKEN_HISTORY_LIST:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
}
