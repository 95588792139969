import { SET_ACCOUNT_ADDRESS } from "./actionsTypes";

const defaultState = {
  loading: false,
  error: false,
  data: {
    address: null,
  },
};

export default function accountReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ACCOUNT_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          address: action.payload,
        },
      };

    default:
      return state;
  }
}
