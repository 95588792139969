import {
  GET_PORTFOLIO_LOADING,
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_ERROR,
} from "./actionsTypes";

import {
  chains as Chains,
  tokens as Tokens,
  userTokens as UserTokens,
  updateManager as UpdateManager,
} from "../actions";

import Services from "../../services";

export function getPortfolio(userOptions) {
  return async function trigger(dispath) {
    dispath({ type: GET_PORTFOLIO_LOADING });
    try {
      const oldHistory = JSON.parse(
        window.localStorage.getItem(`${userOptions.address}_userTokens`) || "[]"
      );
      const lastStoreEventAt =
        (oldHistory[oldHistory.length - 1] || {}).date || 0;
      const currentDate = new Date().getTime();

      const sevices = new Services(userOptions);
      const [chains, tokens, userTokens, userDefiTokens] = await Promise.all([
        sevices.getChains(),
        sevices.getTokens(),
        sevices.getUserTokens(),
        sevices.getUserDefiTokens(),
      ]);

      const mergedTokenList = [...userTokens, ...userDefiTokens, ...tokens]
        .filter(
          (v, i, arr) => arr.findIndex((t) => t.address === v.address) === i
        )
        .map(sevices.formatTokenObject);

      const formatedUserTokens = [...userTokens, ...userDefiTokens].map(
        sevices.formatUserTokenObject
      );

      Chains.updateList(chains)(dispath);
      Tokens.updateList(mergedTokenList)(dispath);
      UserTokens.updateList(formatedUserTokens)(dispath);
      UpdateManager.updateDate()(dispath);
      const history = [
        ...oldHistory,
        { date: currentDate, tokens: formatedUserTokens },
      ];
      UserTokens.updateHistoryList(history)(dispath);

      if (lastStoreEventAt + 60000 * 60 < currentDate) {
        window.localStorage.setItem(
          `${userOptions.address}_userTokens`,
          JSON.stringify(history)
        );
      }

      dispath({ type: GET_PORTFOLIO_SUCCESS });
    } catch (error) {
      console.error(error);
      dispath({ type: GET_PORTFOLIO_ERROR });
    }

    // console.log("userDefiTokens", userDefiTokens);

    // // try {
    // //   await sevices.getPortfolio((data) =>
    // //     dispath({ type: ADD_PORTFOLIO_ITEMS, data })
    // //   );

    // // } catch (error) {
    // //
    // // }
    //
  };
}
