import { v4 } from "uuid";
import Balance from "../../../Common/Balance";
import Card from "../../../Common/Card";
import ChainName from "../../../Common/ChainName";
import Change from "../../../Common/Change";
import Line from "../../../Common/Charts/Line";
import CoinName from "../../../Common/CoinName";
// import MarketName from "../../../Common/MarketName";
import Table from "../../../Common/Table";
import TotalAmount from "../../../Common/TotalAmount";

function getPercentageChange(oldNumber, newNumber) {
  const decreaseValue = newNumber - oldNumber;

  return Number(((decreaseValue / oldNumber) * 100).toFixed(2));
}

const assetTableConfigs = [
  {
    id: v4(),
    key: "chain",
    label: "Chain name",
    Render: ({ value, loading }) => <ChainName id={value} loading={loading} />,
    style: { width: "180px" },
    className: "onlyDesktop",
  },
  // {
  //   id: v4(),
  //   key: "market",
  //   label: "Market name",
  //   Render: ({ value, loading }) => <MarketName {...value} loading={loading} />,
  //   style: { width: "140px" },
  //   className: "onlyDesktop",
  // },
  {
    id: v4(),
    key: "type",
    label: "Type",
    Render: ({ value }) =>
      [...value].map((v, i) => (i === 0 ? v.toUpperCase() : v)).join(""),
    style: { width: "100px" },
    className: "onlyDesktop",
  },
  {
    id: v4(),
    key: "address",
    label: "Name",
    Render: ({ value, loading }) => (
      <CoinName id={value ? [value] : []} loading={loading} />
    ),
    style: { width: "280px" },
  },
  {
    id: v4(),
    key: "history",
    label: "",
    Render: ({ value, loading }) =>
      !loading && (
        <Line
          configs={[{ id: v4(), key: "usd", color: "#0089ec" }]}
          data={value.map((v) => ({
            ...v,
            usd: v.amount * v.price,
          }))}
        />
      ),
    tdContentStyle: { padding: 0 },
    // className: "onlyDesktop",
  },
  {
    id: v4(),
    key: "amount",
    label: "Balance",
    Render: ({ value, loading, object }) => (
      <Balance
        amount={[{ value, price: object().price, symbol: object().symbol }]}
        loading={loading}
      />
    ),
    style: { width: "140px", textAlign: "right" },
  },
  {
    id: v4(),
    key: "history",
    label: "Change",
    Render: ({ value, loading }) => (
      <Change
        percent={getPercentageChange(
          ((value[value.length - 2 || 0] || {}).price || 0) *
            ((value[value.length - 2 || 0] || {}).amount || 0),
          ((value[value.length - 1] || {}).price || 0) *
            ((value[value.length - 1] || {}).amount || 0)
        )}
        loading={loading}
      />
    ),
    style: { width: "100px", textAlign: "right" },
  },
  // {
  //   id: v4(),
  //   key: "type",
  //   label: "",
  //   Render: ({ value, loading }) =>
  //     value === "Farming" && (
  //       <div
  //         style={{
  //           display: "flex",
  //           flex: 1,
  //           alignItems: "center",
  //           justifyContent: "center",
  //           background: "gold",
  //           color: "white",
  //           cursor: "pointer",
  //           height: 32,
  //           borderRadius: 5,
  //         }}
  //       >
  //         Claim
  //       </div>
  //     ),
  //   style: { width: "110px", textAlign: "right" },
  // },
];

export default function Assets({ items, loading = false }) {
  const total = items.reduce((acc, item) => acc + item.amount * item.price, 0);
  return (
    <Card header="DeFi" Aside={() => <TotalAmount value={total} />}>
      <Table configs={assetTableConfigs} data={items} loading={loading} />
    </Card>
  );
}
