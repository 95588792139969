import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AppContainer from "./Layout/AppContainer";
import LeftBar from "./Layout/LeftBar";

import store from "../store";

import "./App.css";
import Container from "./Layout/Container";
import Content from "./Layout/Content";
import Navigation from "./Navigation";
import { Switch } from "react-router-dom";

function App() {
  return (
    <Provider store={store}>
      <AppContainer>
        <Router basename={process.env.REACT_APP_PUBLIC_BASENAME || "./"}>
          <>
            <LeftBar />
            <Switch>
              <Container>
                <Content>
                  <Navigation />
                </Content>
              </Container>
            </Switch>
          </>
        </Router>
      </AppContainer>
    </Provider>
  );
}

export default App;
