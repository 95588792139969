import { SET_ACCOUNT_ADDRESS } from "./actionsTypes";

import Moralis from "moralis/dist/moralis.min.js";

export function connectAccount(userOptions) {
  return async function trigger(dispath) {
    try {
      const user = await Moralis.authenticate({
        signingMessage: "Welcome on Flake Wallet",
      });
      dispath({ type: SET_ACCOUNT_ADDRESS, payload: user.get("ethAddress") });
    } catch (error) {
      console.log(error);
    }
  };
}

export function setNewAccount(userOptions) {
  return async function trigger(dispath) {
    try {
      await Moralis.link(userOptions.address);

      dispath({ type: SET_ACCOUNT_ADDRESS, payload: userOptions.address });
    } catch (error) {
      console.log(error);
    }
  };
}
export function getCurrentAccount(userOptions) {
  return async function trigger(dispath) {
    try {
      dispath({
        type: SET_ACCOUNT_ADDRESS,
        payload: Moralis.User.current().get("ethAddress"),
      });
    } catch (error) {
      console.log(error);
    }
  };
}
