import styled from "styled-components";
import themeProvider from "../../../theme";

export default themeProvider(styled.section`
  background-color: ${({ layout }) =>
    (((layout || {}).colors || {}).container || {}).background};
  border-radius: 0 0 17px 17px;
  position: relative;
  z-index: 0;
  order: 2;
  margin: 70px 20px 20px 20px;
  width: calc(100% - 40px);
  min-height: calc(100vh - 90px);
  height: auto;
`);
