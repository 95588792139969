import styled from "styled-components";
import { withLoading } from "./Loaders";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
  }
  .second {
    margin-top: 3px;
    font-size: 13px;
    color: grey;
  }
`;
const Div = styled.div``;
const ContentLoader = withLoading(Div);

export default function Change({ percent = 0, percentSecond = 0, loading }) {
  return (
    <Container>
      <div style={{ width: "100%" }}>
        <ContentLoader
          className={`text ${percent >= 0 ? "up" : "down"}`}
          loading={loading}
        >
          {percent + " %"}
        </ContentLoader>
        {!!percentSecond && (
          <ContentLoader className="text second" loading={loading}>
            {percentSecond + " %"}
          </ContentLoader>
        )}
      </div>
    </Container>
  );
}
