import {
  UPDATE_USER_TOKEN_LIST,
  UPDATE_USER_TOKEN_HISTORY_LIST,
} from "./actionsTypes";

export function updateList(list) {
  return function trigger(dispath) {
    dispath({ type: UPDATE_USER_TOKEN_LIST, payload: list });
  };
}

export function updateHistoryList(list) {
  return function trigger(dispath) {
    dispath({ type: UPDATE_USER_TOKEN_HISTORY_LIST, payload: list });
  };
}
