import { connect } from "../../store";

export default connect((state) => ({ currency: state.currency }))(function ({
  value,
  currency,
}) {
  return (
    <span style={{ fontWeight: "bold", fontSize: 15 }}>
      {currency.selected.beforeSymbol +
        // " " +
        (value * currency.selected.price).toFixed(2) +
        " " +
        currency.selected.afterSymbol}
    </span>
  );
});
