import { connect } from "../store";
import themeColors from "./colors.json";

export default function themeProvider(Component) {
  function Test({ ...props }) {
    return <Component {...props} />;
  }
  return connect(({ layout }) => ({
    layout: { ...layout, colors: themeColors[layout.themeMode] },
  }))(Test);
}
