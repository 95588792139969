import { v4 } from "uuid";
import CommonChain from "../../common";
import Chains from "../../../externalServices/chains";

export default class CommonDebankService extends CommonChain {
  getChain = async () => {
    return Chains.getChains().then((v) =>
      this.formatChainObject(v.find((v) => v.id === this.options.chain_id))
    );
  };
  getTokenDetail = async (id) => {
    return Chains.getTokenDetail(this.options.chain_id, id);
  };
  getUserTokens = async () => {
    return Chains.getUserTokens(
      this.options.chain_id,
      this.options.address
    ).then((v) =>
      v
        .map(this.formatUserTokenObject)
        .filter((v) => !v.name.includes("Aave Avalanche"))
    );
  };

  getUserDefiTokens = async () => {
    return Chains.getUserDefiTokens(this.options.address).then((v) =>
      v
        .filter((d) => d.chain === this.options.chain_id)
        .map(this.formatUserTokenObject)
    );
  };

  formatChainObject = (object) => ({
    id: this.options.chain_id,
    logo_url: object.logo_url,
    name: object.name,
    address: object.wrapped_token_id,
  });

  formatUserTokenObject = (object) => ({
    id: v4(),
    amount: object.amount,
    chain: this.options.chain_id,
    market: object.market,
    linkTo: object.linkTo,
    logo_url: object.logo_url,
    name: object.name,
    symbol: object.symbol,
    address: object.id,
    price: object.price,
    type: object.type || "wallet",
  });
}
