export function callOnce(fn) {
  let resolvers = [];
  let counter = 0;
  return async (...args) => {
    counter++;
    const id = counter;
    return new Promise((resolve) => {
      resolvers.push(resolve);

      setTimeout(async () => {
        if (id === 1) {
          const result = await fn(...args);
          resolvers.reverse().forEach((resolveFn, index) => {
            if (index === counter - 1) {
              counter = 0;
              resolvers = [];
            }
            resolveFn(result);
          });
        }
      }, 0);
    });
  };
}
