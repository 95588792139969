import { UPDATE_CHAIN_LIST } from "./actionsTypes";

const defaultState = {
  data: [],
};

export default function chainsReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_CHAIN_LIST:
      return {
        ...state,
        data: action.payload.reduce(
          (acc, item) => ({ ...acc, [item.id]: item }),
          {}
        ),
      };

    default:
      return state;
  }
}
