import Chains from "./chains";

export const chains = Chains;

export default class Services {
  methods = [];
  constructor(options) {
    this.services = Object.values(Chains).map(
      (Service) => new Service(options)
    );
  }

  getChains = async () => {
    return Promise.all(
      Object.values(this.services).map((service) => service.getChain())
    );
  };
  getTokens = async () => {
    return Promise.all(
      Object.values(this.services).map((service) => service.getTokens())
    ).then((v) => v.reduce((acc, item) => [...acc, ...item], []));
  };
  getUserTokens = async () => {
    return Promise.all(
      Object.values(this.services).map((service) => service.getUserTokens())
    ).then((v) => v.reduce((acc, item) => [...acc, ...item], []));
  };
  getUserDefiTokens = async () => {
    return Promise.all(
      Object.values(this.services).map((service) => service.getUserDefiTokens())
    ).then((v) => v.reduce((acc, item) => [...acc, ...item], []));
  };
  getUserTransactions = async () => [];

  formatTokenObject = (object) => ({
    id: object.id,
    chain: object.chain,
    logo_url: object.logo_url,
    name: object.name,
    symbol: object.symbol,
    address: object.address,
    price: object.price || 0,
  });

  formatUserTokenObject = (object) => ({
    id: object.id,
    chain: object.chain,
    market: object.market,
    linkTo: object.linkto,
    address: object.address,
    price: object.price || 0,
    amount: object.amount,
    type: object.type,
    symbol: object.symbol,
  });
}
