import { v4 } from "uuid";
import CommonDebankService from "./commons/debank";

export default class AvaxService extends CommonDebankService {
  constructor(options) {
    super({ address: "", ...options, chain_id: "avax", chain: "avalanche" });
  }

  getTokens = async () => {
    return Promise.all([
      fetch(
        "https://raw.githubusercontent.com/pangolindex/tokenlists/main/pangolin.tokenlist.json"
      )
        .then((v) => v.json())
        .then((v) => v.tokens),
      fetch(
        "https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/joe.tokenlist.json"
      )
        .then((v) => v.json())
        .then((v) => v.tokens),
      fetch(
        "https://gist.githubusercontent.com/kesslerdev/7da008e65665f5e61e2dde28d61f2fb9/raw/flake.mainnet.tokenlist.json"
      )
        .then((v) => v.json())
        .then((v) => v.tokens),
    ])
      .then((v) =>
        v
          .reduce((acc, items) => [...acc, ...items.map(this.formatToken)], [])
          .filter(
            (t, i, arr) => arr.findIndex((d) => d.address === t.address) === i
          )
      )
      .then((v) => {
        return Promise.all(
          v.map(
            (item) =>
              new Promise((resolve) => {
                this.getTokenDetail(item.address)
                  .then((itemD) => {
                    console.log("getTOkenDetail", itemD);
                    resolve({ ...item, price: itemD.price });
                  })
                  .catch(() => resolve(item));
              })
          )
        );
      });
  };

  formatToken = (object) => ({
    id: v4(),
    chain: this.options.chain_id,
    decimals: object.decimals,
    address: object.address,
    logo_url: object.logoURI,
    name: object.name,
    symbol: object.symbol,
  });

  formatTokenObject = (object) => ({
    id: v4(),
    amount: object.amount,
    chain: this.options.chain_id,
    logo_url: object.logo_url,
    name: object.name,
    symbol: object.symbol,
    address: object.id,
    price: object.price,
  });
}
