import styled from "styled-components";
import { get } from "lodash";
// import { withLoading } from "./Loaders";

const Container = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;

  tbody tr:hover {
    td > div {
      background: #f1f1f1;
      cursor: pointer;
    }
  }
  tbody tr:last-child {
    td > div {
    }
  }
`;
const Tr = styled.tr`
  height: 70px;
`;
const Th = styled.th`
  height: 55px;
  border-bottom: 1px solid #f3f3f3;
  box-sizing: border-box;
  padding: 0 15px;
  font-weight: normal;
  position: sticky;
`;

const Td = styled.td`
  box-sizing: border-box;
  padding: 0;
`;

const TdContent = styled.div`
  width: 100%;
  height: 70px;
  max-height: 70px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 0 15px;
  border-top: 1px solid #f3f3f3;
  * {
    z-index: 2;
  }
`;

const Label = styled.span`
  display: inline-block;
`;

// const LabelLoader = withLoading(Label);

export default function Table({ loading = true, configs = [], data = [] }) {
  return (
    <Container>
      <thead>
        <tr>
          {configs.map(
            ({ Render, label, key, defaultValue, id, ...otherConfig }) => (
              <Th key={id} {...otherConfig}>
                <Label>{label}</Label>
              </Th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {(loading ? [{ id: "loading1" }, { id: "loading2" }] : data).map(
          (object) => (
            <Tr key={object.id}>
              {configs.map(
                ({
                  Render,
                  key,
                  defaultValue = "",
                  id,
                  tdContentStyle = {},
                  ...otherConfig
                }) => (
                  <Td key={object.id + id} {...otherConfig}>
                    <TdContent style={tdContentStyle}>
                      <Render
                        value={get(object, key, defaultValue)}
                        loading={loading}
                        object={() => object}
                      />
                    </TdContent>
                  </Td>
                )
              )}
            </Tr>
          )
        )}
      </tbody>
    </Container>
  );
}
