import styled from "styled-components";
import { connect } from "../../store";
import { withLoading } from "./Loaders";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
  }
  .second {
    margin-top: 3px;
    font-size: 13px;
    color: grey;
  }
`;
const Div = styled.div``;
const ContentLoader = withLoading(Div);

function Balance({ amount = [], loading = true, oneline = false, currency }) {
  return (
    <Container>
      <div style={{ width: "100%" }}>
        <ContentLoader className="text" loading={loading}>
          {currency.selected.beforeSymbol +
            // " " +
            (
              (amount || []).reduce((acc, it) => it.value * it.price + acc, 0) *
              currency.selected.price
            ).toFixed(2) +
            " " +
            currency.selected.afterSymbol}
        </ContentLoader>
        {!oneline &&
          (amount || []).map((v, i) => (
            <ContentLoader
              key={`loaderBalance${i}`}
              className="text second"
              loading={loading}
            >
              {loading ? "Loading ..." : v.value.toFixed(4)} {v.symbol}
            </ContentLoader>
          ))}
      </div>
    </Container>
  );
}

export default connect((state) => ({ currency: state.currency }))(Balance);
