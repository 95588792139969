import { Route } from "react-router-dom";
import Card from "../Common/Card";
import Portfolio from "./Portfolio";
import Trade from "./Trade";
import Swap from "./Swap";

export default function Navigation() {
  return (
    <>
      <Route path="/" component={Swap} />
      <Route exact path="/" component={Portfolio} />
      <Route strict path="/trade" component={Trade} />
      <Route
        strict
        path="/bots"
        render={() => <Card header="🚀 Comming soon !"></Card>}
      />
      <Route
        strict
        path="/pool"
        render={() => <Card header="🚀 Comming soon !"></Card>}
      />
      <Route
        strict
        path="/farm"
        render={() => <Card header="🚀 Comming soon !"></Card>}
      />
      <Route
        strict
        path="/stake"
        render={() => <Card header="🚀 Comming soon !"></Card>}
      />
      <Route
        strict
        path="/setting"
        render={() => <Card header="🚀 Comming soon !"></Card>}
      />
    </>
  );
}
