import { v4 } from "uuid";

import Card from "../../../Common/Card";
import Line from "../../../Common/Charts/Line";
import TotalAmount from "../../../Common/TotalAmount";

const configs = [
  { id: v4(), key: "wallet", color: "#4bc0c0" },
  { id: v4(), key: "assets", color: "#0089ec" },
  { id: v4(), key: "reward", color: "gold" },
];

export default function TotalBalance({
  walletItems,
  assetsItems,
  rewardItems,
}) {
  const total = [...walletItems, ...assetsItems, ...rewardItems].reduce(
    (acc, item) => acc + item.amount * item.price,
    0
  );

  const walletData = walletItems.reduce((acc, item) => {
    return {
      ...acc,
      ...item.history.reduce(
        (acc2, v) => ({
          ...acc2,
          [v.date]: {
            date: v.date,
            wallet: v.amount * v.price,
          },
        }),
        {}
      ),
    };
  }, {});

  const rewardData = rewardItems.reduce((acc, item) => {
    return {
      ...acc,
      ...item.history.reduce(
        (acc2, v) => ({
          ...acc2,
          [v.date]: {
            date: v.date,
            reward: v.amount * v.price,
          },
        }),
        {}
      ),
    };
  }, {});

  const assetsData = assetsItems.reduce((acc, item) => {
    return {
      ...acc,
      ...item.history.reduce(
        (acc2, v) => ({
          ...acc2,
          [v.date]: {
            date: v.date,
            assets: v.amount * v.price,
          },
        }),
        {}
      ),
    };
  }, {});

  const data = Object.keys({
    ...walletData,
    ...rewardData,
    ...assetsData,
  }).reduce(
    (acc, key) => [
      ...acc,
      {
        wallet: (walletData[key] || {}).wallet || 0,
        reward: (rewardData[key] || {}).reward || 0,
        assets: (assetsData[key] || {}).assets || 0,
      },
    ],
    []
  );
  return (
    <Card header="Portfolio balance" style={{ height: "33vh", with: "100%" }}>
      <div
        style={{
          height: 51,
          display: "flex",
          alignItems: "center",
          padding: "0 15px",
        }}
      >
        <TotalAmount value={total} />
      </div>
      <div
        style={{
          height: "calc(100% - 51px)",
          width: "100%",
        }}
      >
        <Line configs={configs} data={data} />
      </div>
    </Card>
  );
}
