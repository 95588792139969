import { useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 } from "uuid";

import Icon from "../../../Common/Icon";
import Balance from "../../../Common/Balance";
import Card from "../../../Common/Card";
import ChainName from "../../../Common/ChainName";
import Change from "../../../Common/Change";
import Line from "../../../Common/Charts/Line";
import CoinName from "../../../Common/CoinName";
import Table from "../../../Common/Table";
import TotalAmount from "../../../Common/TotalAmount";

function getPercentageChange(oldNumber, newNumber) {
  const decreaseValue = newNumber - oldNumber;

  return Number(((decreaseValue / oldNumber) * 100).toFixed(2));
}

const walletTableConfigs = (push) => [
  {
    id: v4(),
    key: "chain",
    label: "Chain name",
    Render: ({ value, loading }) => <ChainName id={value} loading={loading} />,
    style: { width: "180px" },
    className: "onlyDesktop",
  },
  {
    id: v4(),
    key: "address",
    label: "Name",
    Render: ({ value, loading }) => (
      <CoinName id={value ? [value] : []} loading={loading} />
    ),
    style: { width: "280px" },
  },
  {
    id: v4(),
    key: "history",
    label: "",
    Render: ({ value, loading }) =>
      !loading && (
        <Line
          configs={[{ id: v4(), key: "usd", color: "#4bc0c0" }]}
          data={value.map((v) => ({
            ...v,
            usd: v.amount * v.price,
          }))}
        />
      ),
    tdContentStyle: { padding: 0 },
    // className: "onlyDesktop",
  },
  {
    id: v4(),
    key: "amount",
    label: "Balance",
    Render: ({ value, loading, object }) => (
      <Balance
        amount={[{ value, price: object().price, symbol: object().symbol }]}
        loading={loading}
      />
    ),
    style: { width: "140px", textAlign: "right" },
  },
  {
    id: v4(),
    key: "history",
    label: "Change",
    Render: ({ value, loading }) => (
      <Change
        percent={getPercentageChange(
          ((value[value.length - 2 || 0] || {}).price || 0) *
            ((value[value.length - 2 || 0] || {}).amount || 0),
          ((value[value.length - 1] || {}).price || 0) *
            ((value[value.length - 1] || {}).amount || 0)
        )}
        loading={loading}
      />
    ),
    style: { width: "100px", textAlign: "right" },
  },
  {
    id: v4(),
    key: "chain",
    label: "",
    Render: ({ value, loading, object }) =>
      value === "avax" && (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            background: "#0089ec",
            color: "white",
            cursor: "pointer",
            height: 32,
            borderRadius: 5,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            push(`?action=swap&token0=${object().address}`);
          }}
        >
          <Icon name="swap_horiz" />
        </div>
      ),
    style: { width: "80px", textAlign: "right" },
  },
];

function useSearch(items) {
  const [search, setSearch] = useState("");
  const data = search ? items.filter((v) => v.name.includes(search)) : items;

  return { data, setSearch, search };
}

function Wallet({ items, loading = false, history }) {
  const { data } = useSearch(items);
  const total = items.reduce((acc, item) => acc + item.amount * item.price, 0);

  return (
    <Card
      header="Wallet"
      Aside={() => <TotalAmount value={total} />}
      style={{ overflow: "visible" }}
    >
      <Table
        configs={walletTableConfigs(history.push)}
        data={data}
        loading={loading}
      />
    </Card>
  );
}

export default withRouter(Wallet);
