import styled from "styled-components";
import themeProvider from "../../../theme";
import { NavLink } from "react-router-dom";

export const Container = themeProvider(styled.header`
  width: 100vw;
  height: 70px;
  /* transition: width 0.3s; */
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 1;
  order: 1;
  background-color: ${({ layout }) =>
    (((layout || {}).colors || {}).appContainer || {}).background};
  border-radius: 0 0 17px 17px;
`);

export const Brand = themeProvider(styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  height: 100%;

  .logo {
    display: flex;
    width: 50px;
    min-width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      background: white;
      width: 45px;
      border-radius: 45px;
    }
  }

  span {
    display: ${({ layout }) => (layout.exandLeftbar ? "block" : "none")};
    box-sizing: border-box;
    padding-left: 15px;
    font-size: 18px;
    color: white;
    white-space: nowrap;
    font-weight: bold;
  }
`);

export const Navigation = styled.nav`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: none;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 10px;
`;

export const NavTop = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const NavBottom = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding-right: 10px;
`;

export const NavItem = styled(NavLink)`
  display: flex;
  height: 50px;
  margin: 0 5px;
  text-decoration: none;
  color: white;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
  box-sizing: border-box;

  .tooltip {
    display: none;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 9;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 8px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
  }

  &.active {
    background: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    .tooltip {
      display: block;
      opacity: 1;

      @media (min-width: 1350px) {
        display: none;
      }
    }
  }
  &:active {
    background: rgba(0, 0, 0, 0.4);
  }
`;
export const NavItemAction = styled.div`
  display: flex;
  height: 50px;
  margin: 0 5px;
  text-decoration: none;
  color: white;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
  box-sizing: border-box;

  .tooltip {
    display: none;
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 9;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 8px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
  }

  &.active {
    background: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    .tooltip {
      display: block;
      opacity: 1;

      @media (min-width: 1350px) {
        display: none;
      }
    }
  }
  &:active {
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const NavItemIcon = styled.div`
  width: 50px;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    width: 28px;
    height: 28px;
    font-size: 28px;
    display: block;
  }
`;

export const NavItemLabel = themeProvider(styled.span`
  display: none;
  box-sizing: border-box;
  font-size: 14px;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  padding-right: 15px;

  @media (min-width: 1350px) {
    display: inline-block;
  }
`);

export const WalletItemLabel = themeProvider(styled.span`
  display: inline-block;
  box-sizing: border-box;
  font-size: 14px;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  padding: 0 15px;
`);
