import styled from "styled-components";
import { connect } from "../../store";
import { withLoading } from "./Loaders";

export const Container = styled.div`
  display: flex;
  align-items: center;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 110px;
    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
  .second {
    font-size: 13px;
    color: grey;
  }
`;
const Div = styled.div``;
const ContentLoader = withLoading(Div);

const Img = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 45px;
  background: white;
`;

function ChainName({ id = "", chains, loading = true }) {
  const item = chains[id] || {};

  return (
    <Container>
      <ContentLoader
        loading={loading}
        style={{
          borderRadius: "45px",
          marginRight: "15px",
          height: "35px",
          width: "35px",
        }}
      >
        <Img id={item.logo_url} src={item.logo_url || undefined} alt="" />
      </ContentLoader>
      <div>
        <ContentLoader className="text" loading={loading}>
          {item.name || "Loading ..."}
        </ContentLoader>
      </div>
    </Container>
  );
}

export default connect((state) => ({ chains: state.chains.data }))(ChainName);
