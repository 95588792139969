import { CHANGE_CURRENCY } from "./actionsTypes";

const defaultState = {
  currencies: [
    {
      id: "EUR",
      name: "EUR",
      afterSymbol: "€",
      beforeSymbol: "",
      local: "fr",
      price: 0.86,
    },
    {
      id: "USD",
      name: "USD",
      afterSymbol: "",
      beforeSymbol: "$",
      local: "en",
      price: 1,
    },
  ],
  selected: {
    id: "EUR",
    name: "EUR",
    afterSymbol: "€",
    beforeSymbol: "",
    local: "fr",
    price: 0.86,
  },
};

export default function currencyReducer(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_CURRENCY:
      return {
        ...state,
        selected: action.payload,
      };

    default:
      return state;
  }
}
