import Moralis from "moralis/dist/moralis.min.js";
import { useEffect } from "react";
import Jazzicon from "jazzicon";

import { NavItemAction, NavItemIcon, WalletItemLabel } from "./Styled";
import { connect } from "../../../store";
import { account } from "../../../store/actions";

export const getEllipsisTxt = (str, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

function ConnectWallet({
  address,
  connectAccount,
  setNewAccount,
  getCurrentAccount,
}) {
  const handleConnect = () => {
    connectAccount();
  };

  useEffect(() => {
    if (address) {
      const img = Jazzicon(16, parseInt(address.slice(2, 10), 16));
      img.style.zoom = 1.5;
      //   img.style.height = "50px";

      document.getElementById(address).innerHTML = "";
      document.getElementById(address).appendChild(img);
    }
  }, [address]);

  useEffect(() => {
    Moralis.enableWeb3().then(() => {
      getCurrentAccount();
      Moralis.onAccountChanged(async (newAddress) => {
        setNewAccount({ address: newAddress });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Moralis, Moralis.enableWeb3]);

  return (
    <NavItemAction
      onClick={address ? () => {} : handleConnect}
      className="active"
    >
      {address ? (
        <>
          <WalletItemLabel style={{ paddingRight: 0 }}>
            {getEllipsisTxt(address)}
          </WalletItemLabel>
          <NavItemIcon id={address}></NavItemIcon>
        </>
      ) : (
        <WalletItemLabel>Connect</WalletItemLabel>
      )}
    </NavItemAction>
  );
}

export default connect(({ account }) => ({ address: account.data.address }), {
  connectAccount: account.connectAccount,
  setNewAccount: account.setNewAccount,
  getCurrentAccount: account.getCurrentAccount,
})(ConnectWallet);
