import { useEffect } from "react";
import { connect } from "../../../../store";
import { portfolio, updateManager } from "../../../../store/actions";

import Row from "../../../Common/Row";

import Assets from "./Assets";
import Rewards from "./Rewards";
import TotalBalance from "./TotalBalance";
import Wallet from "./Wallet";

function PortfolioV2({
  getPortfolio,
  portfolio,
  userAddress,
  userTokens,
  canUpdate,
  canUpdateData,
}) {
  console.log(canUpdate);
  useEffect(() => {
    canUpdateData();
    if (userAddress && canUpdate) getPortfolio({ address: userAddress });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress, canUpdate]);

  const loading = portfolio.loading;

  const walletItems = userTokens.filter((item) => item.type === "wallet");
  const rewardItems = userTokens.filter((item) => item.type === "reward");
  const assetsItems = userTokens.filter(
    (item) => item.type !== "reward" && item.type !== "wallet"
  );

  return (
    <div key={userAddress}>
      <Row>
        <TotalBalance
          walletItems={walletItems}
          rewardItems={rewardItems}
          assetsItems={assetsItems}
        />
      </Row>
      <Row>
        <Wallet items={walletItems} loading={loading} />
        <Rewards items={rewardItems} loading={loading} />
      </Row>
      <Row>
        <Assets items={assetsItems} loading={loading} />
      </Row>
    </div>
  );
}

export default connect(
  ({ account, portfolio, userTokens, updateManager }) => ({
    portfolio,
    userTokens: userTokens.data.map((token) => ({
      ...token,
      history: userTokens.history.reduce((acc, item) => {
        const historyItem = item.tokens.find(
          (v) => v.address === token.address && v.type === token.type
        );
        if (historyItem) return [...acc, { ...historyItem, date: item.date }];
        return acc;
      }, []),
    })),
    userAddress: account.data.address,
    canUpdate: updateManager.canUpdate,
  }),
  {
    getPortfolio: portfolio.getPortfolio,
    canUpdateData: updateManager.canUpdateData,
  }
)(PortfolioV2);
