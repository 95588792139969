import styled from "styled-components";
import themeProvider from "../../../theme";

export default themeProvider(styled.div`
  width: 100%;
  height: 100%;
  background-color: #0169b5;
  /* overflow: hidden; */
  flex-direction: column;
  overflow: auto;

  .onlyDesktop {
    @media (max-width: 1350px) {
      display: none;
    }
  }
`);
