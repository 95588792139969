import React from "react";
import ReactDOM from "react-dom";
import Moralis from "moralis/dist/moralis.min.js";

import "./index.css";
import App from "./Components/App";

const serverUrl = "https://m1bqgfdxzncj.usemoralis.com:2053/server";
const appId = "GTHQeG3qxestbu22q21iS43ZaKzzNY5HjcNsEOSv";

// eslint-disable-next-line no-undef
Moralis.start({ serverUrl, appId });

ReactDOM.render(
  <React.StrictMode>
    {/* <MoralisProvider appId={appId} serverUrl={serverUrl}> */}
    <App />
    {/* </MoralisProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
