import { UPDATE_DATE, CAN_UPDATE_DATA } from "./actionsTypes";

export function updateDate(userOptions) {
  return async function trigger(dispath) {
    dispath({ type: UPDATE_DATE });
  };
}

export function canUpdateData() {
  return async function trigger(dispath) {
    dispath({ type: CAN_UPDATE_DATA });
  };
}
